<template>
  <div>
    <div class="container">
      <b-row class="rowas">
        <div class="col-12 col-sm-12 col-md-12 noPadding plusAncho title">
          <p><img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/lupa-executive2.png">Login <span>Page</span></p>
        </div>
        <b-row id="form">
          <div class="col-12">
              <b-alert variant="danger" show v-if="error">User Not Found!</b-alert>
          </div>
          <div class="col-12">
            <b-form-group
              id="fieldset-email"
              label-cols-sm="4"
              label-cols-lg="3"
              label="Email"
              label-for="email"
              invalid-feedback="Invalid email"
              :state="emailState"
            >
              <b-form-input id="email" v-model="data.body.email"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group
                id="fieldset-password"
                label-cols-sm="4"
                label-cols-lg="3"
                label="Password"
                label-for="password"
                invalid-feedback="Password must be at least 6 characters long"
                :state="passwordState"
              >
              <b-form-input id="password" type="password" v-model="data.body.password"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-button variant="success" @click="login" :disabled="!readyToSend">Login</b-button>
          </div>
        </b-row>
      </b-row>
      <b-row class="clearfix" style="height: 50px;"></b-row>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        data: {
            body: {
                email : "",
                password : ""
            },
            rememberMe: true,
            fetchUser: true
        },
        error: null,
        loginStart: true,
      }
    },
    computed: {
      emailState() {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (
          re.test(String(this.data.body.email).toLowerCase()) || this.loginStart
        );
      },
      passwordState() {
        return this.data.body.password.length >= 6 || this.loginStart;
      },
      readyToSend() {
        return this.emailState && this.passwordState;
      }
    },
    methods: {
      login() {
        this.loginStart = false;
        
        if(this.emailState && this.passwordState) {
          this.$auth.login({
              data: this.data.body, // Axios
              rememberMe: this.data.rememberMe,
              redirect: {name: 'dashboard'},
              fetchUser: this.data.fetchUser
          })
          .then(() => {
          }, (res) => {
              this.error = res.response.data;
              console.dir(this.error)
          });
        }
      }
    },
  }
</script>

<style scoped>
  #form {
    margin-left: auto;
    margin-right: auto;
  }
</style>